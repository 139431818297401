






























































































































































































































































































































.el-form-item {
  margin-bottom: 18px;
}
.czzjf {
  width: 150px;
  margin: auto;
}
.czzjf /deep/ .el-form-item__content {
  margin-left: 0 !important;
}
